import { Injectable } from '@angular/core';
import { Observable, of, from, BehaviorSubject } from 'rxjs';
import { SponsorInfo } from './sponsor-info';
import { CookieService } from 'ngx-cookie';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class SponsorService {
  private _sponsor: BehaviorSubject<SponsorInfo> = new BehaviorSubject(null)
  private _badSpons: boolean = false;

  constructor(
    private cookie: CookieService,
    private afs: AngularFireFunctions,
  ) {
    this.getSponsorCookies()
    // this.verify('10001')
  }

  get sponsor() {
    return this._sponsor.asObservable()
  }

  get sponsorID() {
    const tmp_spons = this._sponsor.getValue()
    if (tmp_spons) {
      return tmp_spons.id
    } else { return null }
  }


  getSponsorCookies() {
    const sponsor_tmp: SponsorInfo = {
      id: this.cookie.get('SponsorID'),
      name: this.cookie.get('SponsorName'),
      email: this.cookie.get('SponsorEmail'),
      sitename: this.cookie.get('ShowName'),
      phone: this.cookie.get('SponsorPhone'),
    }
    this._sponsor.next(sponsor_tmp)
  }

  setSponsor(sponsorArray) {
    console.log('setting sponsor')
    const data: SponsorInfo = {
      id: sponsorArray[1],
      name: sponsorArray[2],
      email: sponsorArray[4],
      sitename: sponsorArray[5],
      phone: sponsorArray[3],
    }
    this._sponsor.next(data)
    this.setSponsorCookies(data)
  }

  setSponsorCookies(s_data: SponsorInfo) {
    var ed = new Date()
    ed = new Date(ed.getFullYear()+1,ed.getMonth(),ed.getDate())
    this.cookie.put('SponsorID', s_data.id, {'domain':'carebeyond.com', 'expires':ed})
    this.cookie.put('SponsorName', s_data.name, {'domain':'carebeyond.com', 'expires':ed})
    this.cookie.put('SponsorEmail', s_data.email, {'domain':'carebeyond.com', 'expires':ed})
    this.cookie.put('SponsorPhone', s_data.phone, {'domain':'carebeyond.com', 'expires':ed})
    this.cookie.put('ShowName', s_data.sitename, {'domain':'carebeyond.com', 'expires':ed})
  }

  sponsorCheck(sponsorID: string) {
    const tmp_spons = this._sponsor.getValue()
    if(tmp_spons) {
      if ((sponsorID == tmp_spons.id) || (sponsorID == tmp_spons.sitename)) {
        return
      } else {
        this.verify(sponsorID)
      }
    } else {
      this.verify(sponsorID)
    }
  }

  async verify(sponsorID: string) {
     const scheck = this.afs.httpsCallable('sponsorCheck');
     scheck({sponsorid: sponsorID})
      .toPromise()
      .then(resp => {
        const sponsorString = <string>resp;
        console.log(sponsorString)
        const sponsorArray = sponsorString.split('|')
        // if real sponsor ID
        if (sponsorArray[0] === '0'){
          this.setSponsor(sponsorArray)
        } else {
          this._sponsor.next(null)
          this._badSpons = true
        }
      })
      .catch(err => {
        console.error({ err });
      });
   }

}
