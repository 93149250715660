import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { OpportunityComponent } from './opportunity/opportunity.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { LearnComponent } from './learn/learn.component';
import { LandingComponent } from './landing/landing.component';
import { SayingComponent } from './saying/saying.component';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, HomeComponent, AboutUsComponent, OpportunityComponent, ContactUsComponent, LearnComponent, LandingComponent, SayingComponent],
  imports: [
    CommonModule
  ],
  exports: [ HeaderComponent, FooterComponent]
})
export class UiModule { }
