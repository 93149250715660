import { Component, AfterViewInit, ViewChild, ElementRef, OnInit, TemplateRef } from '@angular/core';
import { SponsorService } from '../../core/sponsor.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

declare var $: any;
var resizeId;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit, OnInit {
  @ViewChild('home',{static: false}) private homeRef: ElementRef;
  @ViewChild('learn',{static: false}) private learnRef: ElementRef;
  @ViewChild('about',{static: false}) private aboutRef: ElementRef;
  @ViewChild('saying',{static: false}) private sayingRef: ElementRef;
  @ViewChild('opportunity',{static: false}) private opportunityRef: ElementRef;
  @ViewChild('contact',{static: false}) private contactRef: ElementRef;

  navItems=['Home','Learn More','About Us','Opportunity', 'What People are Saying', 'Contact'];
  navArray: ElementRef[];
  modalRef: BsModalRef;
  slideIndex = 1;
  

  constructor(
    private sponsor: SponsorService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
  ) {
    this.route.params.subscribe((params) => {
      if (params['sponsor']) {
        this.sponsor.sponsorCheck(params['sponsor'])
        console.log('test')
      }
      
    })
    
  }
  


  plusSlides(n) {
    this.slideIndex += n;
    this.showSlides(this.slideIndex);
  }

  currentSlide(n) {
    this.showSlides(this.slideIndex = n);
  }

  showSlides(n) {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    var dots = document.getElementsByClassName("dot");
    if (n > 5) {this.slideIndex = 1}    
    if (n < 1) {this.slideIndex = 5}
    for (i = 0; i < slides.length; i++) {
    }
    for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
    }
    dots[this.slideIndex-1].className += " active";
  }

  ngAfterViewInit(){
    this.navArray=[this.homeRef,this.learnRef,this.aboutRef,
                              this.opportunityRef, this.sayingRef, this.contactRef];
   
  }

  ngOnInit(): void {
    $(document).ready(() => {

    

      if( document.getElementsByClassName("ts-full-screen").length ) {
           
           $(".ts-full-screen").css({"height": window.innerHeight + "px"});
      }
      $("body").imagesLoaded( function() {
        $("body").addClass("loading-done");
          $("[data-animate]").scrolla({
              mobile: true
          });
      });

    $('.navbar-nav .nav-link').on('click', function(){
      $('.navbar-collapse').collapse('hide');
    });

    $(".ts-img-into-bg").each(function() {
        $(this).css("background-image", "url("+ $(this).find("img").attr("src") +")" );
    });

    //  Background

    $("[data-bg-color], [data-bg-image], [data-bg-particles]").each(function() {
        var $this = $(this);

        if( $this.hasClass("ts-separate-bg-element") ){
            $this.append('<div class="ts-background">');

            // Background Color

            if( $("[data-bg-color]") ){
                $this.find(".ts-background").css("background-color", $this.attr("data-bg-color") );
            }


            // Background Image

            if( $this.attr("data-bg-image") !== undefined ){
                $this.find(".ts-background").append('<div class="ts-background-image">');
                $this.find(".ts-background-image").css("background-image", "url("+ $this.attr("data-bg-image") +")" );
                $this.find(".ts-background-image").css("background-size", $this.attr("data-bg-size") );
                $this.find(".ts-background-image").css("background-position", $this.attr("data-bg-position") );
                $this.find(".ts-background-image").css("opacity", $this.attr("data-bg-image-opacity") );

                $this.find(".ts-background-image").css("background-size", $this.attr("data-bg-size") );
                $this.find(".ts-background-image").css("background-repeat", $this.attr("data-bg-repeat") );
                $this.find(".ts-background-image").css("background-position", $this.attr("data-bg-position") );
                $this.find(".ts-background-image").css("background-blend-mode", $this.attr("data-bg-blend-mode") );
            }

            // Parallax effect

            if( $this.attr("data-bg-parallax") !== undefined ){
                $this.find(".ts-background-image").addClass("ts-parallax-element");
            }
        }
        else {

            if(  $this.attr("data-bg-color") !== undefined ){
                $this.css("background-color", $this.attr("data-bg-color") );
                if( $this.hasClass("btn") ) {
                    $this.css("border-color", $this.attr("data-bg-color"));
                }
            }

            if( $this.attr("data-bg-image") !== undefined ){
                $this.css("background-image", "url("+ $this.attr("data-bg-image") +")" );

                $this.css("background-size", $this.attr("data-bg-size") );
                $this.css("background-repeat", $this.attr("data-bg-repeat") );
                $this.css("background-position", $this.attr("data-bg-position") );
                $this.css("background-blend-mode", $this.attr("data-bg-blend-mode") );
            }

        }
    });

    //  Parallax Background Image

    $("[data-bg-parallax='scroll']").each(function() {
        var speed = $(this).attr("data-bg-parallax-speed");
        var $this = $(this);
        var isVisible;
        var backgroundPosition;

        $this.isInViewport(function(status) {
            if (status === "entered") {
                isVisible = 1;
                var position;

                $(window).scroll(function () {
                    if( isVisible === 1 ){
                        position = $(window).scrollTop() - $this.offset().top;
                        backgroundPosition = (100 - (Math.abs((-$(window).height()) - position) / ($(window).height()+$this.height()))*100);
                        if( $this.find(".ts-parallax-element").hasClass("ts-background-image") ){
                            $this.find(".ts-background-image.ts-parallax-element").css("background-position-y", (position/speed) + "px");
                        }
                        else {
                            $this.find(".ts-parallax-element").css("transform", "translateY(" +(position/speed)+ "px)");
                        }
                    }
                });
            }
            if (status === "leaved"){
                isVisible = 0;
            }
        });
    });

    $(".ts-labels-inside-input input, .ts-labels-inside-input textarea").focusin(function() {
        $(this).parent().find("label").addClass("focused");
        })
        .focusout(function() {
            if( $(this).val().length === 0 ){
                $(this).parent().find("label").removeClass("focused")
        }
    });

    $("select").each(function(){
        $(this).wrap('<div class="select-wrapper"></div>');
    });




    // On SCROLL actions

    $(window).on("scroll", function(){
        if ( $(window).scrollTop() >= $(window).height() ) {
            $(".navbar").addClass("in");
        }
        else {
            $(".navbar").removeClass("in");
        }
      });
    });
    
  }

  scroller(i:number) {
    this.navArray[i].nativeElement.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef=this.modalService.show(template, Object.assign({}, { class: 'modal-dialog-centered modal-lg' }));
  }


}
