// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCt414uMvOG4Q9zKhVGsWEpH7zE8xIKULk",
    authDomain: "kinlocks-tax.firebaseapp.com",
    databaseURL: "https://kinlocks-tax.firebaseio.com",
    projectId: "kinlocks-tax",
    storageBucket: "",
    messagingSenderId: "456622074305"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
